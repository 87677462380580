<template>
  <v-row>
    <v-col cols="12">
        <product-card></product-card> 
    </v-col>
  </v-row>
</template>
<script>
import ProductCard from "@/components/product/ProductCard";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Product List",
  },

  components: {
    ProductCard
  },

  data() {
    return {
    };
  },
};
</script>