<template>
  <div>
    <base-card>
      <div>
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="6">{{ $t("productPage.product") }}</v-col>
            <v-col cols="12" lg="2">
              <v-select
                v-model="selectCategoriesItem"
                :items="categoriesItem"
                item-value="category_id"
                item-text="name"
                dense
                label="Solo field"
                solo
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4"
              ><v-text-field
                class="mt-n3"
                v-model="productSearch"
                append-icon="mdi-magnify"
                :label="$t('productPage.searchProductName')"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-title>

        <div class="text-center" v-if="loadProduct">
          <v-progress-circular
            class="mb-16"
            :size="40"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>

        <div v-show="productList != ''">
          <v-card-text>
            <div class="d-flex justify-space-between flex-wrap">
              <v-btn
                class="ma-2"
                small
                dark
                color="primary"
                @click="(title = 'Add'), openDialog()"
              >
                <v-icon small>mdi-plus</v-icon>
                {{ $t("productPage.addProduct") }}
              </v-btn>
              <div>
                <v-btn
                  small
                  outlined
                  @click="dialogImport = true"
                  class="ma-2"
                  >{{ $t("productPage.import") }}</v-btn
                >
                <!-- ------------------------------------line-------->
                <!-- <v-btn
                class="ma-2"
                small
                dark
                color="danger"
                v-if="selected != ''"
                @click="dialogDelete = true"
              >
                <v-icon small>mdi-delete</v-icon>
                delete
              </v-btn> -->
                <!-- ------------------------------------line-------->
              </div>
              <v-spacer></v-spacer>
            </div>
          </v-card-text>

          <v-data-table
            :search="productSearch"
            :headers="headers"
            :items="productList"
            :page.sync="page"
            :no-data-text="$t('dailyModifierSalesPage.noData')"
            item-key="product_id"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            @click:row="handleClick"
          >
            <template v-slot:item.category_name="{ item }">
              <span v-if="item.category_name == null">No category</span>
              <span v-else>{{ item.category_name }}</span>
            </template>

            <template v-slot:item.quantity="{ item }">
              <span v-if="item.stock_type == 1"
                >{{ item.daily_limit }}({{ item.daily_limit_amount }})</span
              >
              <span v-else>{{ item.stock_quantity }}</span>
            </template>

            <template v-slot:item.available="{ item }">
              <span v-if="item.available == 1">Available</span>
              <span v-else>Not Available</span>
            </template>
          </v-data-table>
          <div class="text-center py-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </div>
        <div v-show="productList == ''" v-if="!loadProduct">
          <v-card-text class="text-center">
            <div class="pa-16">
              <v-btn
                class="mx-2"
                fab
                x-large
                disabled
                depressed
                style="font-size: 399px !important"
              >
                <v-icon x-large> mdi-package-variant </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <div class="my-2">
                <span class="text-subtitle-1">{{
                  $t("productPage.noProductFound")
                }}</span>
              </div>

              <v-spacer></v-spacer>
              <v-btn
                small
                dark
                color="primary"
                @click="(title = 'Add'), openDialog()"
              >
                <v-icon>mdi-plus</v-icon>
                {{ $t("productPage.addProduct") }}
              </v-btn>
              <v-btn small outlined class="ml-2" @click="dialogImport = true">{{
                $t("productPage.import")
              }}</v-btn>
            </div>
          </v-card-text>
        </div>
      </div>
    </base-card>

    <v-dialog v-model="dialogImport" max-width="600px">
      <v-card>
        <v-card-title
          ><span class="text-h6 font-weight-black">{{
            $t("productPage.importProduct")
          }}</span></v-card-title
        >
        <v-card-text>
          <a @click="downloadTemplate">{{
            $t("productPage.downloadTemplateFile")
          }}</a>
          {{ $t("productPage.downloadHint") }}

          <VueFileAgent
            ref="vueFileAgent"
            :deletable="true"
            :maxSize="'10MB'"
            :maxFiles="1"
            :helpText="$t('productPage.uploadFile')"
            v-model="fileArray"
            :accept="'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'"
            @change="onChange($event)"
            @beforedelete="onBeforeDelete($event)"
          ></VueFileAgent>
          <!-- {{ fileRecords }} -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            @click="(dialogImport = false), onBeforeDelete()"
            text
            color="red lighten-2"
          >
            {{ $t("productPage.cancel") }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="buttonLoading"
            @click="uploadProduct(), (buttonLoading = true)"
            text
          >
            {{ $t("productPage.upload") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" fullscreen eager width="800">
      <base-card>
        <v-card-text>
          <div class="text-center" v-if="this.title == 'Edit' && !loadComplete">
            <v-progress-circular
              :size="40"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-form
            ref="form"
            v-show="loadComplete"
            v-model="valid"
            lazy-validation
          >
            <div class="mb-5 ms-3">
              <v-row>
                <span class="text-h6"
                  >{{ title }} {{ $t("productPage.product") }}</span
                >
                <v-spacer></v-spacer>

                <v-btn color="danger" icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon></v-btn
                >
              </v-row>
            </div>
            <v-expansion-panels mandatory multiple v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">{{
                  $t("productPage.productInfo")
                }}</v-expansion-panel-header>
                <v-expansion-panel-content class="mt-n5">
                  <v-row>
                    <v-col cols="7" lg="7">
                      <v-text-field
                        prepend-icon="mdi-alphabetical-variant"
                        :label="$t('productSalesPage.productName')"
                        v-model="pName"
                        :rules="[
                          (v) => !!v || $t('productPage.productIsRequired'),
                        ]"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="5" lg="5">
                      <v-select
                        v-model="selectCategory"
                        :items="categoriesList"
                        item-text="name"
                        item-value="category_id"
                        :label="$t('sideBar.categories')"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-textarea
                    counter
                    :label="$t('productPage.description')"
                    v-model="pDescription"
                    prepend-icon="mdi-comment"
                    :rules="[
                      (v) => v.length <= 100 || $t('productPage.maxCharacters'),
                    ]"
                    rows="3"
                  ></v-textarea>

                  <v-row>
                    <v-col cols="9" lg="9">
                      <v-text-field
                        v-model="pPrice"
                        type="number"
                        prepend-icon="mdi-currency-usd"
                        :label="$t('productPage.price')"
                        :rules="[
                          (v) => !!v || $t('productPage.required'),
                          (v) => v >= 0 || $t('productPage.priceNoNegative'),
                        ]"
                        @change="
                          list.length == 0
                            ? updateBranchVariant()
                            : updateEditData(),
                            (selectBranchVariantList = [])
                        "
                        :hints="$t('productPage.priceHint')"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="3" lg="3">
                      <v-select
                        solo
                        v-model="selectUnit"
                        :items="unitList"
                        :label="$t('productPage.unit')"
                      ></v-select>
                    </v-col>
                  </v-row>

                  <v-text-field
                    v-show="selectUnit != 'each' && selectUnit != 'custom'"
                    prepend-icon="mdi-weight"
                    v-model="selectUnitAmount"
                    type="number"
                    :rules="
                      selectUnit != 'each' && selectUnit != 'custom'
                        ? unitAmountNotEachRules
                        : unitAmountEachRules
                    "
                    :label="$t('productPage.unitAmount')"
                    :suffix="selectUnit"
                  ></v-text-field>

                  <v-text-field
                    prepend-icon="mdi-format-list-numbered"
                    v-model="sequenceNumber"
                    type="number"
                    :rules="[
                      (v) =>
                        /null|^(|\d)+$/.test(v) ||
                        $t('productPage.productSequenceNumberRules'),
                    ]"
                    :label="$t('productPage.productSequenceNumber')"
                  >
                    <template v-slot:append>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                        </template>
                        {{ $t("productPage.sequenceNumberHint") }}
                      </v-tooltip>
                    </template>
                  </v-text-field>

                  <v-text-field
                    v-model="pSKU"
                    prepend-icon="mdi-tag"
                    :label="$t('productPage.sku')"
                    :hint="$t('productPage.skuHint')"
                    persistent-hint
                    :rules="skuRules"
                    @change="
                      list.length == 0
                        ? updateBranchVariant()
                        : updateEditData(),
                        (selectBranchVariantList = [])
                    "
                  ></v-text-field>

                  <v-checkbox
                    v-model="availableSave"
                    false-value="0"
                    true-value="1"
                    :label="$t('productPage.availableForSale')"
                  ></v-checkbox>
                  <v-checkbox
                    v-show="selectUnit == 'each'"
                    class="mt-n4"
                    v-model="showInQR"
                    false-value="0"
                    true-value="1"
                    :label="$t('productPage.showItemInQR')"
                  ></v-checkbox>
                  <v-checkbox
                    class="mt-n4"
                    v-model="hasVariant"
                    false-value="0"
                    true-value="1"
                    :label="$t('productPage.hasVariant')"
                    :disabled="lockVariant"
                  ></v-checkbox>

                  <v-checkbox
                    class="mt-n4"
                    v-model="allowTicket"
                    false-value="0"
                    true-value="1"
                    :label="$t('productPage.allowPrintTicket')"
                  >
                  </v-checkbox>
                  <v-text-field
                    v-show="allowTicket == '1'"
                    prepend-icon="mdi-ticket"
                    class="mt-n3"
                    v-model="ticketNumber"
                    type="number"
                    :rules="[
                      (v) =>
                        /null|^(|\d)+$/.test(v) ||
                        $t('productPage.ticketRules'),
                    ]"
                    :label="$t('productPage.ticketNumber')"
                  ></v-text-field>

                  <v-text-field
                    v-show="allowTicket == '1'"
                    prepend-icon="mdi-calendar-today"
                    v-model="ticketExp"
                    type="number"
                    :rules="[
                      (v) =>
                        /null|^(|\d)+$/.test(v) ||
                        $t('productPage.expiredDateRules'),
                    ]"
                    :label="$t('productPage.expiredDate')"
                  ></v-text-field>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">
                  {{ $t("productPage.stock") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-radio-group
                    v-model="stockType"
                    :disabled="radioGroupDisallow"
                    row
                    @change="
                      list.length == 0
                        ? updateBranchVariant()
                        : updateEditData(),
                        (selectBranchVariantList = [])
                    "
                  >
                    <v-radio
                      :label="$t('productPage.dailyLimitText')"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      :label="$t('productPage.stock')"
                      :value="2"
                    ></v-radio>
                    <v-radio
                      :label="$t('productPage.unlimitedStockText')"
                      :value="3"
                    ></v-radio>
                  </v-radio-group>

                  <v-text-field
                    v-if="stockType == '1' && this.title == 'Add'"
                    v-model="dailyLimit"
                    type="number"
                    prepend-icon="mdi-package-variant"
                    :label="$t('productPage.dailyLimitField')"
                    :rules="[
                      (v) =>
                        /^(|\d)+$/.test(v) || $t('productPage.inputMustNumber'),
                      (v) => v > 0 || $t('productPage.dailyLimitRules'),
                    ]"
                    @change="
                      list.length == 0
                        ? updateBranchVariant()
                        : updateEditData(),
                        (selectBranchVariantList = [])
                    "
                  ></v-text-field>

                  <v-text-field
                    v-else-if="stockType == '2' && this.title == 'Add'"
                    v-model="stockQuantity"
                    type="number"
                    prepend-icon="mdi-package-variant"
                    :label="$t('productPage.stockQuantity')"
                    :rules="[
                      (v) =>
                        /^(|\d)+$/.test(v) || $t('productPage.inputMustNumber'),
                      (v) => v > 0 || $t('productPage.stockQuantityRules'),
                    ]"
                    @change="
                      list.length == 0
                        ? updateBranchVariant()
                        : updateEditData(),
                        (selectBranchVariantList = [])
                    "
                  ></v-text-field>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-show="hasVariant == '1'">
                <v-expansion-panel-header class="font-weight-bold">
                  {{ $t("productPage.variant") }}</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <div v-show="list.length != 0">
                    <span
                      >{{ $t("productPage.variant") }}:
                      <span
                        v-for="(item, index) in list"
                        :item="item"
                        :index="index"
                        :key="item.sequence"
                        >{{ item.name }}
                        <span v-if="index < list.length - 1">| </span></span
                      ></span
                    >
                    <v-spacer></v-spacer>
                    <br />
                  </div>
                  <v-btn
                    v-if="list.length == 0"
                    depressed
                    class="primary--text"
                    color="lighten-5 primary"
                    @click="
                      setList(), openDialogOption(), (optionTitle = 'Add')
                    "
                  >
                    {{ $t("productPage.addOption") }}
                  </v-btn>
                  <v-btn
                    v-else
                    depressed
                    class="primary--text"
                    color="lighten-5 primary"
                    @click="openDialogOption(), (optionTitle = 'Edit')"
                  >
                    {{ $t("productPage.editOption") }}
                  </v-btn>
                  <div v-show="list.length != 0">
                    <v-data-table
                      :headers="variantHeader"
                      :items="variantList"
                      :page.sync="variantPage"
                      item-key="SKU"
                      :items-per-page="itemsPerPage"
                      @page-count="variantPageCount = $event"
                      hide-default-footer
                    >
                      <template v-slot:item.price="props">
                        <v-edit-dialog :return-value.sync="props.item.price">
                          {{ props.item.price }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.price"
                              :rules="[
                                (v) => !!v || $t('productPage.required'),
                                (v) =>
                                  v >= 0 || $t('productPage.priceNoNegative'),
                              ]"
                              type="number"
                              @change="
                                updateBranchVariant(),
                                  (selectBranchVariantList = [])
                              "
                            ></v-text-field>
                          </template> </v-edit-dialog
                      ></template>
                      <template v-slot:item.quantity="props">
                        <v-edit-dialog
                          v-if="stockType != '3'"
                          :return-value.sync="props.item.quantity"
                        >
                          {{ props.item.quantity }}
                          <template v-slot:input>
                            <v-text-field
                              v-model="props.item.quantity"
                              :rules="[
                                (v) =>
                                  /^(|\d)+$/.test(v) ||
                                  $t('productPage.inputMustNumber'),
                                (v) =>
                                  v >= 0 ||
                                  $t('productPage.quantityNotNegative'),
                                (v) =>
                                  stockType != '3'
                                    ? !!v || $t('productPage.required')
                                    : '',
                              ]"
                              type="number"
                              @change="
                                updateBranchVariant(),
                                  // selectBranchVariantList = [...branchVariantList];
                                  (selectBranchVariantList = [])
                              "
                            ></v-text-field>
                          </template> </v-edit-dialog
                      ></template>
                    </v-data-table>
                    <div class="text-center pt-2">
                      <v-pagination
                        v-model="variantPage"
                        :length="variantPageCount"
                        circle
                      ></v-pagination>
                    </div>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">
                  {{ $t("productPage.modifier") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="v-input--reverse mt-n1"
                    v-model="switch1"
                    v-for="(mod, index) in modifierList"
                    :item="mod"
                    :index="index"
                    :key="mod.id"
                    :value="mod.id"
                  >
                    <template v-slot:label>
                      <div>
                        <span class="font-weight-bold">{{ mod.name }}</span>
                      </div>
                      <span class="text-body-2">
                        {{ $t("productPage.availableIn") }}
                        <span
                          v-for="(item, index) in mod.branch"
                          :item="item"
                          :index="index"
                          :key="item.branch_id"
                          >{{ item.branch_name
                          }}<span v-if="index < mod.branch.length - 1"
                            >,
                          </span></span
                        >
                      </span>
                    </template>
                  </v-switch>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">
                  {{ $t("salesSummaryPage.branch") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-n3">
                    <v-col class="d-none d-md-flex" cols="12" md="6">
                      <v-switch
                        v-model="checkAllBranchLinkProduct"
                        :label="checkAllBranchLinkProductLabel"
                      ></v-switch
                    ></v-col>
                    <v-col class="d-none d-md-flex" cols="12" md="2"></v-col>
                    <v-col cols="12" md="4">
                      <v-text-field
                        v-model="branchSearch"
                        prepend-icon="mdi-magnify"
                        :label="$t('productPage.search')"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="branchHeader"
                    :items="branchVariantList"
                    :search="branchSearch"
                    show-select
                    v-model="selectBranchVariantList"
                    item-key="id"
                    :page.sync="branchPage"
                    :items-per-page="itemsPerPage"
                    @page-count="branchPageCount = $event"
                    hide-default-footer
                  >
                    <template v-slot:item.price="props">
                      <v-edit-dialog
                        :return-value.sync="props.item.price"
                        large
                        persistent
                        @save="save"
                      >
                        {{ props.item.price }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="props.item.price"
                            :rules="[
                              (v) => !!v || $t('productPage.required'),
                              (v) =>
                                v >= 0 || $t('productPage.priceNoNegative'),
                            ]"
                            type="number"
                          ></v-text-field>
                        </template> </v-edit-dialog
                    ></template>
                    <template v-slot:item.quantity="props">
                      <v-edit-dialog
                        v-if="stockType != '3'"
                        :return-value.sync="props.item.quantity"
                        large
                        persistent
                        @save="save"
                      >
                        {{ props.item.quantity }}
                        <template v-slot:input>
                          <v-text-field
                            v-model="props.item.quantity"
                            :rules="[
                              (v) =>
                                /^(|\d)+$/.test(v) ||
                                $t('productPage.inputMustNumber'),
                              (v) =>
                                v >= 0 || $t('productPage.quantityNotNegative'),
                              (v) =>
                                stockType != '3'
                                  ? !!v || $t('productPage.required')
                                  : '',
                            ]"
                            type="number"
                          ></v-text-field>
                        </template> </v-edit-dialog
                    ></template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-pagination
                      v-model="branchPage"
                      :length="branchPageCount"
                      circle
                    ></v-pagination>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="font-weight-bold">
                  {{ $t("productPage.picture") }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-radio-group v-model="radioValue" row>
                    <v-radio
                      :label="$t('productPage.color')"
                      value="1"
                    ></v-radio>
                    <v-radio
                      :label="$t('productPage.picture')"
                      value="2"
                    ></v-radio>
                  </v-radio-group>
                  <v-swatches
                    v-if="radioValue == '1'"
                    v-model="color"
                    :swatches="swatches"
                    :label="$t('productPage.chooseColor')"
                    inline
                  ></v-swatches>
                  <div v-if="radioValue == '2'">
                    <croppa
                      :placeholder="$t('productPage.chooseDropImage')"
                      :placeholder-font-size="14"
                      :show-loading="false"
                      :file-size-limit="1048576"
                      v-model="myCroppa"
                      accept=".jpg, .png, .jpeg"
                      :prevent-white-space="true"
                      @file-size-exceed="onFileSizeExceed"
                      @new-image-drawn="onFileChoose"
                    >
                      <img
                        crossOrigin="anonymous"
                        :src="imageName"
                        slot="initial"
                      />
                    </croppa>
                    <v-spacer class="my-2"></v-spacer>
                    <span class="text-body-2">{{
                      $t("productPage.imageHint")
                    }}</span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            :loading="buttonSave"
            @click="dialogAction()"
            text
          >
            {{ $t("productPage.save") }}
          </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1">{{
          $t("productPage.deleteProductConfirm")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false">{{
            $t("productPage.cancel")
          }}</v-btn>
          <v-btn color="blue darken-1" @click="deleteProduct" text>{{
            $t("productPage.ok")
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogOption" max-width="550px" persistent>
      <v-card>
        <v-card-text class="pt-4">
          <div class="mb-5">
            <span class="text-h6"
              >{{ optionTitle }} {{ $t("productPage.variant") }}</span
            >
          </div>
          <v-form ref="optionForm" v-model="validOption" lazy-validation
            ><draggable
              :list="tempList"
              @start="drag = true"
              @end="drag = false"
              disabled="true"
              class="mb-2"
            >
              <v-list
                v-for="(element, idx) in tempList"
                :key="element.sequence"
              >
                <v-row class="mb-n5">
                  <v-col cols="1"
                    ><v-icon class="row-pointer">mdi-menu</v-icon></v-col
                  >

                  <v-col cols="3">
                    <v-text-field
                      v-model="element.name"
                      class="mt-n4"
                      :label="$t('productPage.optionName')"
                      :rules="[
                        (v) => !!v || $t('productPage.variantNameRequired'),
                      ]"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="7">
                    <v-combobox
                      v-model="element.item"
                      class="mt-n6"
                      style="margin-top: -26px !important"
                      deletable-chips
                      :rules="[
                        (v) =>
                          !!v.length > 0 ||
                          $t('productPage.variantItemRequired'),
                      ]"
                      append-icon=""
                      :hint="$t('productPage.editVariantItemHint')"
                      multiple
                      chips
                    ></v-combobox>
                  </v-col>

                  <v-col cols="1"
                    ><v-icon @click="removeAt(idx)">mdi-close</v-icon></v-col
                  >
                </v-row>
              </v-list>
            </draggable>
          </v-form>
          <v-spacer></v-spacer>
          <v-btn
            v-show="tempList.length < 3"
            depressed
            class="primary--text"
            color="lighten-5 primary"
            @click="add"
          >
            {{ $t("productPage.addOption") }}
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeList()">{{
            $t("productPage.close")
          }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="saveList()">{{
            $t("productPage.save")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import draggable from "vuedraggable";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  ruleNumber: (v, label = null) => {
    return (
      Number.isInteger(Number(v)) ||
      `The ${label ?? "field"} must be a valid integer`
    );
  },
  components: {
    draggable,
    VSwatches,
  },
  data() {
    return {
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      snackbar: false,
      text: "",
      snackbarColor: "",
      timeout: 1900,
      checkAllBranchLinkProduct: false,
      companyID: "",
      stockType: "",
      dailyLimit: "",
      stockQuantity: "",
      productList: [],
      panel: [0, 1, 2, 3, 4, 5],
      categoriesList: [],
      selectCategory: "",
      modifierList: [],
      switch1: [],
      radioValue: "",
      pDescription: "",
      myCroppa: {},
      dataUrl: "",
      availableSave: "",
      showInQR: "",
      pName: "",
      pPrice: "",
      dialog: false,
      title: "",
      valid: "",
      selectUnit: "each",
      radioGroupDisallow: false,
      selectUnitAmount: "",
      sequenceNumber: "",
      // unitAmountEachRules: [
      //   (v) => v >= 0 || this.$t("productPage.unitAmountNoNegative"),
      // ],
      // unitAmountNotEachRules: [
      //   (v) => !!v || this.$t("productPage.required"),
      //   (v) => v >= 0 || this.$t("productPage.unitAmountNoNegative"),
      // ],
      unitList: ["each", "kg", "g", "l", "ml", "custom"],

      list: [],
      tempList: [],

      tempVariantList: [],

      categoriesItem: [],
      selectCategoriesItem: -1,
      //for import
      dialogImport: false,
      fileRecords: "",
      fileArray: [],
      //end
      selectBranch: [],
      branchList: [],
      hasVariant: 0,
      buttonSave: false,
      allowTicket: "",
      ticketNumber: "",
      ticketExp: "",

      dialogOption: false,
      validOption: "",
      loadProduct: true,
      optionTitle: "",
      variantList: [],
      branchVariantList: [],
      variantPage: 1,
      variantPageCount: 0,
      branchPage: 1,
      branchPageCount: 0,
      itemsPerPage: 10,
      productSearch: "",

      selectBranchVariantList: [],
      imageName: "",
      pSKU: "",
      // skuRules: [
      //   (v) => !!v || this.$t("productPage.skuIsRequired"),
      //   (v) => (v && v.length >= 2) || this.$t("productPage.skuAtLeast2char"),
      // ],

      branch: [],
      selectProduct: "",
      image: "",
      cardLoading: true,
      buttonLoading: false,
      page: 1,
      // total: 0,
      pageCount: 0,
      itemsPerPage: 10,
      dialogDelete: false,
      branchSearch: "",
      lockVariant: false,
      loadComplete: false,
      selected: [],
      color: "#E0E0E0",
      swatches: [
        "#E0E0E0",
        "#F44366",
        "#E91E63",
        "#FF9800",
        "#CDDC39",
        "#4CAF50",
        "#2196F3",
        "#9C27B0",
      ],
      // new add
      productModifier: [],
      productVariantGroup: [],
      productVariantList: [],
      productBranchList: [],
      tempProductVariantGroup: [],
      tempProductVariantList: [],
    };
  },

  computed: {
    skuRules() {
      return [
        (v) => !!v || this.$t("productPage.skuIsRequired"),
        (v) => (v && v.length >= 2) || this.$t("productPage.skuAtLeast2char"),
      ];
    },
    unitAmountEachRules() {
      return [(v) => v >= 0 || this.$t("productPage.unitAmountNoNegative")];
    },
    unitAmountNotEachRules() {
      return [
        (v) => !!v || this.$t("productPage.required"),
        (v) => v >= 0 || this.$t("productPage.unitAmountNoNegative"),
      ];
    },

    checkAllBranchLinkProductLabel() {
      if (!this.checkAllBranchLinkProduct) {
        return this.$t("productPage.selectAllBranch");
      } else {
        return this.$t("productPage.unselectAllBranch");
      }
    },
    branchHeader() {
      if (this.list.length == 0) {
        return [
          {
            text: this.$t("salesSummaryPage.branch"),
            sortable: false,
            value: "name",
          },
          {
            text: this.$t("productPage.price"),
            sortable: false,
            value: "price",
          },
          {
            text:
              this.stockType == 1
                ? this.$t("productPage.dailyLimit")
                : this.$t("productPage.stock"),
            sortable: false,
            value: "quantity",
          },
        ];
      } else {
        return [
          {
            text: this.$t("salesSummaryPage.branch"),
            sortable: false,
            value: "name",
          },

          {
            text: this.$t("productPage.variant"),
            sortable: false,
            value: "variant",
          },
          {
            text: this.$t("productPage.price"),
            sortable: false,
            value: "price",
          },
          {
            text:
              this.stockType == 1
                ? this.$t("productPage.dailyLimit")
                : this.$t("productPage.stock"),
            sortable: false,
            value: "quantity",
          },
        ];
      }
    },

    variantHeader() {
      return [
        {
          text: this.$t("productPage.variant"),
          sortable: false,
          value: "name",
        },
        {
          text: this.$t("productPage.price"),
          sortable: false,
          value: "price",
        },
        {
          text:
            this.stockType == 1
              ? this.$t("productPage.dailyLimit")
              : this.$t("productPage.stock"),
          sortable: false,
          value: "quantity",
        },
        {
          text: this.$t("productPage.sku"),
          sortable: false,
          value: "sku",
        },
      ];
    },
    headers() {
      return [
        {
          text: this.$t("productSalesPage.productName"),
          align: "start",
          sortable: false,
          value: "name",
        },

        {
          text: "SKU",
          align: "start",
          sortable: true,
          value: "SKU",
        },

        {
          text: this.$t("productPage.category"),
          align: "start",
          sortable: true,
          value: "category_name",
        },

        {
          text: this.$t("productPage.price"),
          align: "start",
          sortable: true,
          value: "price",
        },
        // {
        //   text: "In Stock",
        //   align: "start",
        //   sortable: false,
        //   value: "quantity",
        // },
        {
          text: this.$t("productPage.status"),
          align: "start",
          sortable: false,
          value: "available",
        },
      ];
    },
    // pageCount: {
    //   get() {
    //     return this.total;
    //   },
    //   set(newName) {
    //     return newName;
    //   },
    // },
  },

  watch: {
    selectCategoriesItem() {
      process.nextTick(() => {
        this.loadProduct = true;
      });

      if (this.selectCategoriesItem == -1) {
        this.getAllProductList(this.companyID);
      } else {
        this.page = 1;
        this.getAllCategoryProductList(
          this.companyID,
          this.selectCategoriesItem
        );
      }
    },
    checkAllBranchLinkProduct() {
      if (this.checkAllBranchLinkProduct == false) {
        this.selectBranchVariantList = [];
      } else {
        this.selectBranchVariantList = [...this.branchVariantList];
      }
    },
    hasVariant() {
      if (this.hasVariant == "0") {
        this.tempList = [];
        this.tempVariantList = [];
        this.list = [];
        this.variantList = [];
        this.selectBranchVariantList = [];
        this.updateBranchVariant();
      }
    },
    selectUnit() {
      if (this.selectUnit != "each" && this.selectUnit != "custom") {
        this.stockType = 3;
        this.radioGroupDisallow = true;
        this.dailyLimit = "";
        this.stockQuantity = "";
      } else {
        this.stockType = 2;
        this.selectUnitAmount = "";
        this.radioGroupDisallow = false;
      }
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllCategoriesItem();
    // this.getAllProduct();
    this.choosePage();
    this.getCompanyBranch();
  },

  methods: {
    onFileChoose(e) {
      this.dataUrl = this.myCroppa.generateDataUrl("image/jpeg", 0.8);
    },
    onFileSizeExceed(file) {
      this.text = this.$t("productPage.fileRestiction");
      this.snackbar = true;
      this.snackbarColor = "error";
      this.myCroppa.refresh();
    },
    async saveList() {
      await this.$refs.optionForm.validate();
      if (this.validOption) {
        this.tempVariantList = [];
        var num = 0;
        if (this.tempList[1] && this.tempList[0] && this.tempList[2]) {
          for (let j = 0; j < this.tempList[0].item.length; j++) {
            for (let k = 0; k < this.tempList[1].item.length; k++) {
              for (let m = 0; m < this.tempList[2].item.length; m++) {
                this.tempVariantList.push({
                  name:
                    this.tempList[0].item[j] +
                    " | " +
                    this.tempList[1].item[k] +
                    " | " +
                    this.tempList[2].item[m],
                  price: this.pPrice,
                  quantity:
                    this.stockType == 1
                      ? this.dailyLimit
                      : this.stockType == 2
                      ? this.stockQuantity
                      : "",
                  sku: this.pSKU + num++,
                });
              }
            }
          }
        } else if (this.tempList[1] && this.tempList[0]) {
          for (let j = 0; j < this.tempList[0].item.length; j++) {
            for (let k = 0; k < this.tempList[1].item.length; k++) {
              this.tempVariantList.push({
                name:
                  this.tempList[0].item[j] + " | " + this.tempList[1].item[k],
                price: this.pPrice,
                quantity:
                  this.stockType == 1
                    ? this.dailyLimit
                    : this.stockType == 2
                    ? this.stockQuantity
                    : "",
                sku: this.pSKU + num++,
              });
            }
          }
        } else if (this.tempList[0]) {
          for (let j = 0; j < this.tempList[0].item.length; j++) {
            this.tempVariantList.push({
              name: this.tempList[0].item[j],
              price: this.pPrice,
              quantity:
                this.stockType == 1 ? this.dailyLimit : this.stockQuantity,
              sku: this.pSKU + num++,
            });
          }
        }

        const findDifference = (arr1, arr2, key) => {
          const set1 = new Set(arr1.map((item) => item[key]));
          const set2 = new Set(arr2.map((item) => item[key]));

          return {
            uniqueToArr1: arr1.filter((item) => !set2.has(item[key])),
            uniqueToArr2: arr2.filter((item) => !set1.has(item[key])),
          };
        };

        const { uniqueToArr1, uniqueToArr2 } = findDifference(
          this.tempVariantList,
          this.variantList,
          "name"
        );

        uniqueToArr1.forEach((item) => {
          this.variantList.push(item);
        });

        let idsToRemove = new Set(uniqueToArr2.map((item) => item.name));
        let resultArray = this.variantList.filter(
          (item) => !idsToRemove.has(item.name)
        );

        resultArray.forEach((item, index) => {
          item.sku = this.pSKU + index;
        });

        console.log(resultArray);

        this.list = JSON.parse(JSON.stringify(this.tempList));
        this.tempVariantList = JSON.parse(JSON.stringify(resultArray));
        this.variantList = JSON.parse(JSON.stringify(resultArray));

        this.selectBranchVariantList = [];
        this.updateBranchVariant();

        this.dialogOption = false;

        // this.selectBranchVariantList = [...this.branchVariantList];
      } else if (!this.validOption) {
        this.dialogOption = true;
      }
    },
    // ---------------------
    updateEditData() {
      var num = 0;
      for (let i = 0; i < this.variantList.length; i++) {
        this.variantList[i].price = this.pPrice;
        this.variantList[i].sku = this.pSKU + num++;
        this.variantList[i].quantity =
          this.stockType == 1
            ? this.dailyLimit
            : this.stockType == 2
            ? this.stockQuantity
            : "";
      }

      this.updateBranchVariant();
      this.selectBranchVariantList = [];
    },

    removeAt(idx) {
      this.tempList.splice(idx, 1);
    },
    add: function () {
      if (this.$refs.optionForm.validate()) {
        var sequence = this.tempList.length;
        sequence = sequence + 1;
        this.tempList.push({ name: "", item: [], sequence: sequence, id: "" });
      }
    },

    closeList() {
      this.dialogOption = false;
      this.tempList = JSON.parse(JSON.stringify(this.list));
    },

    getAllCategoriesItem() {
      const params = new URLSearchParams();
      params.append("getAllCategories", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/categories/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var item = response.data.categories;
            item.unshift(
              {
                name: "All",
                color: "",
                items: "",
                sequence: "",
                category_id: -1,
              },

              {
                name: "No Category",
                color: "",
                items: "",
                sequence: "",
                category_id: 0,
              }
            );
            this.categoriesItem = item;
          } else {
            this.categoriesItem = [
              {
                name: "All",
                color: "",
                items: "",
                sequence: "",
                category_id: -1,
              },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllCategories() {
      const params = new URLSearchParams();
      params.append("getAllCategories", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/categories/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var array = response.data.categories;

            array.unshift({
              name: "No Category",
              color: "",
              items: "",
              sequence: "",
              category_id: 0,
            });
            this.categoriesList = array;
          } else {
            this.categoriesList = [
              {
                name: "No Category",
                color: "",
                items: "",
                sequence: "",
                category_id: 0,
              },
            ];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branch = response.data.branch;
          } else {
            this.branch = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateBranchVariant() {
      var array = this.branch;
      this.branchVariantList = [];
      for (let i = 0; i < array.length; i++) {
        if (this.variantList == "") {
          this.branchVariantList.push({
            branch_id: array[i].branch_id,
            price: this.pPrice,
            quantity:
              this.stockType == 1
                ? this.dailyLimit
                : this.stockType == 2
                ? this.stockQuantity
                : "",
            name: array[i].name,
            id: array[i].branch_id + this.pSKU,
          });
        } else {
          for (let k = 0; k < this.variantList.length; k++) {
            this.branchVariantList.push({
              branch_id: array[i].branch_id,
              variant: this.variantList[k].name,
              price:
                this.variantList[k].price == ""
                  ? this.pPrice
                  : this.variantList[k].price,
              quantity: this.variantList[k].quantity,
              name: array[i].name,
              id: array[i].branch_id + this.variantList[k].sku,
            });
          }
        }
      }
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // getAllProduct() {
    //   const params = new URLSearchParams();
    //   params.append("getAllProduct", "done");
    //   params.append("company_id", this.companyID);

    //   axios({
    //     method: "post",
    //     url: this.domain + "/product/index.php",
    //     data: params,
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status == "1") {
    //         this.productList = response.data.product;
    //         this.cardLoading = false;
    //         this.loadProduct = false;
    //       } else {
    //         this.productList = [];
    //         this.cardLoading = false;
    //         this.loadProduct = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    //paging
    // getAllProductList(page, company_id) {
    //   const params = new URLSearchParams();
    //   params.append("getAllProductPaging", "done");
    //   params.append("page", page);
    //   params.append("company_id", company_id);
    //   axios({
    //     method: "post",
    //     url: this.domain + "/product/index.php",
    //     data: params,
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status == "1") {
    //         this.productList = response.data.product;
    //         this.total = response.data.number_of_page;
    //         this.cardLoading = false;
    //         this.loadProduct = false;
    //       } else {
    //         this.productList = [];
    //         this.cardLoading = false;
    //         this.loadProduct = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    getAllProductList(company_id) {
      const params = new URLSearchParams();
      params.append("getAllProductLatest", "done");
      params.append("company_id", company_id);
      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.productList = response.data.product;
            this.cardLoading = false;
            this.loadProduct = false;
          } else {
            this.productList = [];
            this.cardLoading = false;
            this.loadProduct = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadTemplate() {
      import("@/data/Export2Excel").then((excel) => {
        const tHeader = [
          "Product_name",
          "Category_id",
          "Price",
          "Description",
          "SKU",
          "Stock_type(1=daily_limit, 2=stock, 3=unlimited_stock)",
          "Stock_amount",
          "product_available(0=Not, 1=Yes)",
          "sequence_number(optional)",
        ];
        const data = "";
        excel.export_json_to_excel({
          header: tHeader, //Header Required
          data, //Specific data Required
          filename: "product_import_template", //Optional
          autoWidth: true, //Optional
          bookType: "xlsx", //Optional
        });
      });
    },

    // onChange() {
    //   //   var XLSX = require("xlsx");
    //   this.files = event.target.files ? event.target.files[0] : null;
    //   if (this.files) {
    //     const reader = new FileReader();

    //     reader.onload = (e) => {
    //       /* Parse data */
    //       const bstr = e.target.result;
    //       const wb = XLSX.read(bstr, { type: "binary" });
    //       /* Get first worksheet */
    //       const wsname = wb.SheetNames[0];
    //       const ws = wb.Sheets[wsname];
    //       /* Convert array of arrays */
    //       const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    //       this.fileRecords = data;
    //     };

    //     reader.readAsBinaryString(this.files);
    //   }
    // },

    onChange() {
      this.files = event.target.files ? event.target.files[0] : null;
      if (this.files) {
        var reader = new FileReader();

        reader.onload = (e) => {
          var data = new Uint8Array(e.target.result);
          var workbook = XLSX.read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          let worksheet = workbook.Sheets[sheetName];
          this.fileRecords = XLSX.utils.sheet_to_json(worksheet);
        };

        reader.readAsArrayBuffer(this.files);
      }
    },

    onBeforeDelete(fileArray) {
      this.$refs.vueFileAgent.deleteFileRecord(fileArray);
      this.fileRecords = "";
    },

    uploadProduct() {
      if (this.fileRecords) {
        const params = new URLSearchParams();
        params.append("uploadProduct", "done");
        params.append("product_array", JSON.stringify(this.fileRecords));
        params.append("company_id", this.companyID);
        axios({
          method: "post",
          url: this.domain + "/product/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.buttonLoading = false;
              for (let i = 0; i < this.branch.length; i++) {
                this.sendNotication(this.branch[i].branch_id);
              }
              this.dialogImport = false;
              this.text =
                response.data.upload_product_response +
                this.$t("productPage.successCreate");
              this.snackbar = true;
              this.snackbarColor = "success";
              this.onBeforeDelete();
              this.choosePage();
            } else if (response.data.status == "2") {
              this.buttonLoading = false;
              this.text =
                response.data.upload_product_response +
                this.$t("productPage.skuRepeatedErrorMsg");
              this.snackbar = true;
              this.snackbarColor = "error";
            } else if (response.data.status == "3") {
              this.buttonLoading = false;
              this.text = this.$t("productPage.stockHasTextErrorMsg");
              this.snackbar = true;
              this.snackbarColor = "error";
            } else {
              this.buttonLoading = false;
              this.text = this.$t("productPage.failErrorMsg");
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        process.nextTick(() => {
          this.buttonLoading = false;
        });

        this.text = this.$t("productPage.noFileAttach");
        this.snackbar = true;
        this.snackbarColor = "error";
      }
    },

    //paging for category item
    // getAllCategoryProductList(company_id, category_id) {
    //   const params = new URLSearchParams();
    //   params.append("getAllCategoryProductPaging", "done");
    //   // params.append("page", page);
    //   params.append("company_id", company_id);
    //   params.append("category_id", category_id);

    //   axios({
    //     method: "post",
    //     url: this.domain + "/product/index.php",
    //     data: params,
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       if (response.data.status == "1") {
    //         this.productList = response.data.product;
    //         this.total = response.data.number_of_page;
    //         this.cardLoading = false;
    //         this.loadProduct = false;
    //       } else {
    //         this.productList = [];
    //         this.cardLoading = false;
    //         this.loadProduct = false;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    getAllCategoryProductList(company_id, category_id) {
      const params = new URLSearchParams();
      params.append("getAllCategoryProductLatest", "done");
      params.append("company_id", company_id);
      params.append("category_id", category_id);

      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.productList = response.data.product;
            this.cardLoading = false;
            this.loadProduct = false;
          } else {
            this.productList = [];
            this.cardLoading = false;
            this.loadProduct = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // choose page to load pickup
    choosePage() {
      if (this.selectCategoriesItem == -1) {
        this.getAllProductList(this.companyID);
      } else {
        this.getAllCategoryProductList(
          this.companyID,
          this.selectCategoriesItem
        );
      }
    },

    getAllModifier() {
      const params = new URLSearchParams();
      params.append("getAllModifier", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/modifier/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.modifierList = response.data.modifier;
          } else {
            this.modifierList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDialog() {
      this.loadComplete = false;
      this.getAllCategories();
      this.getAllModifier();
      if (this.title == "Add") {
        this.dialog = true;
        this.selectCategory = 0;
        this.radioValue = "1";
        this.availableSave = "1";
        this.showInQR = "1";
        this.allowTicket = "0";
        this.ticketNumber = "";
        this.ticketExp = "";
        this.hasVariant = "0";
        this.stockType = 2;
        this.selectUnit = "each";
        this.selectUnitAmount = "";
        this.sequenceNumber = "";
        this.pName = "";
        this.pDescription = "";
        this.pPrice = "";
        this.pSKU = "";
        this.switch1 = [];
        this.imageName = "";
        this.dailyLimit = "";
        this.stockQuantity = "";
        this.lockVariant = false;
        this.selectBranchVariantList = [];
        this.list = [];
        this.variantList = [];
        this.updateBranchVariant();
        process.nextTick(() => {
          this.$refs.form.resetValidation();
        });
        setTimeout(() => {
          this.loadComplete = true;
        }, "200");
      } else {
        this.dialog = true;
        setTimeout(() => {
          this.loadComplete = true;
        }, "600");
      }
    },

    setList() {
      this.tempList = [{ name: "", item: [], sequence: 1, id: "" }];
    },

    openDialogOption() {
      if (this.optionTitle == "Add") {
        this.dialogOption = true;
        process.nextTick(() => {
          this.$refs.optionForm.resetValidation();
        });
      } else {
        this.dialogOption = true;
        this.tempList = JSON.parse(JSON.stringify(this.list));
      }
    },

    getProductModifier(product_id) {
      const params = new URLSearchParams();
      params.append("getProductModifier", "done");
      params.append("product_id", product_id);

      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var modifier = [];
            this.productModifier = response.data.product;
            // modifier
            var modifierArray = this.productModifier;
            for (let i = 0; i < modifierArray.length; i++) {
              modifier.push(parseInt(modifierArray[i].mod_group_id));
            }
            this.switch1 = modifier;
          } else {
            var modifier = [];
            this.productModifier = [];
            // modifier
            var modifierArray = this.productModifier;
            for (let i = 0; i < modifierArray.length; i++) {
              modifier.push(parseInt(modifierArray[i].mod_group_id));
            }
            this.switch1 = modifier;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getProductVariantGroup(product_id) {
      const params = new URLSearchParams();
      params.append("getProductVariantGroup", "done");
      params.append("product_id", product_id);

      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.productVariantGroup = response.data.product;
            //variant group
            var variantArray = [];
            var num = 1;
            var variant = this.productVariantGroup;

            for (let i = 0; i < variant.length; i++) {
              var array = [];
              var j = JSON.parse(variant[i].item);
              for (let z = 0; z < j.length; z++) {
                array.push(j[z].name);
              }
              var obj = {
                id: variant[i].variant_group_id,
                name: variant[i].name,
                item: array,
                sequence: num++,
              };
              variantArray.push(obj);
            }

            this.tempProductVariantGroup = variantArray;
            this.getProductBranch(product_id);
          } else {
            this.productVariantGroup = [];
            //variant group
            var variantArray = [];
            var num = 1;
            var variant = this.productVariantGroup;

            for (let i = 0; i < variant.length; i++) {
              var array = [];
              var j = JSON.parse(variant[i].item);
              for (let z = 0; z < j.length; z++) {
                array.push(j[z].name);
              }
              var obj = {
                id: variant[i].variant_group_id,
                name: variant[i].name,
                item: array,
                sequence: num++,
              };
              variantArray.push(obj);
            }
            this.tempProductVariantGroup = variantArray;
            this.getProductBranch(product_id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getProductVariant(product_id) {
      const params = new URLSearchParams();
      params.append("getProductVariant", "done");
      params.append("product_id", product_id);

      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.productVariantList = response.data.product;
            //product variant
            var productVariantArray = [];
            var pVariant = this.productVariantList;
            for (let i = 0; i < pVariant.length; i++) {
              if (pVariant[i].stock_type == 1) {
                var myObj = {
                  name: pVariant[i].variant_name,
                  price: pVariant[i].price,
                  quantity: pVariant[i].daily_limit,
                  sku: pVariant[i].SKU,
                  // id: pVariant[i].product_variant_id,
                };
                productVariantArray.push(myObj);
              } else {
                var myObj = {
                  name: pVariant[i].variant_name,
                  price: pVariant[i].price,
                  quantity: pVariant[i].stock_quantity,
                  sku: pVariant[i].SKU,
                  // id: pVariant[i].product_variant_id,
                };
                productVariantArray.push(myObj);
              }
            }

            this.tempProductVariantList = productVariantArray;
            this.getProductBranch(product_id);
          } else {
            this.productVariantList = [];
            //product variant
            var productVariantArray = [];
            var pVariant = this.productVariantList;
            for (let i = 0; i < pVariant.length; i++) {
              if (pVariant[i].stock_type == 1) {
                var myObj = {
                  name: pVariant[i].variant_name,
                  price: pVariant[i].price,
                  quantity: pVariant[i].daily_limit,
                  sku: pVariant[i].SKU,
                  // id: pVariant[i].product_variant_id,
                };
                productVariantArray.push(myObj);
              } else {
                var myObj = {
                  name: pVariant[i].variant_name,
                  price: pVariant[i].price,
                  quantity: pVariant[i].stock_quantity,
                  sku: pVariant[i].SKU,
                  // id: pVariant[i].product_variant_id,
                };
                productVariantArray.push(myObj);
              }
            }
            this.tempProductVariantList = productVariantArray;
            this.getProductBranch(product_id);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getProductBranch(product_id) {
      const params = new URLSearchParams();
      params.append("getProductBranch", "done");
      params.append("product_id", product_id);

      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.productBranchList = response.data.product;
            // product branch
            var productBranchArray = [];
            var branchProduct = this.productBranchList;
            for (let k = 0; k < branchProduct.length; k++) {
              if (branchProduct[k].stock_type == 1) {
                var branchPush = {
                  branch_id: branchProduct[k].bid,
                  variant: branchProduct[k].variant,
                  price: branchProduct[k].price,
                  quantity: branchProduct[k].daily_limit,
                  name: branchProduct[k].branch_name,
                  id: branchProduct[k].b_SKU,
                };
                productBranchArray.push(branchPush);
              } else {
                var branchPush = {
                  branch_id: branchProduct[k].bid,
                  variant: branchProduct[k].variant,
                  price: branchProduct[k].price,
                  name: branchProduct[k].branch_name,
                  quantity: branchProduct[k].stock_quantity,
                  id: branchProduct[k].b_SKU,
                };
                productBranchArray.push(branchPush);
              }
            }

            this.selectBranchVariantList = productBranchArray;

            if (this.hasVariant == "0") {
              this.variantList = [];
              this.list = [];
            } else {
              this.variantList = this.tempProductVariantList;
              this.list = this.tempProductVariantGroup;
            }
            var array = this.branch;

            this.branchVariantList = [];
            let clonedArray = [...productBranchArray];
            var arr = clonedArray;
            for (let i = 0; i < array.length; i++) {
              if (this.variantList == "") {
                arr.push({
                  branch_id: array[i].branch_id,
                  price: this.pPrice,
                  quantity:
                    this.stockType == 1
                      ? this.dailyLimit
                      : this.stockType == 2
                      ? this.stockQuantity
                      : "",
                  name: array[i].name,
                  id: array[i].branch_id + this.pSKU,
                });
              } else {
                for (let k = 0; k < this.variantList.length; k++) {
                  arr.push({
                    branch_id: array[i].branch_id,
                    variant: this.variantList[k].name,
                    price:
                      this.variantList[k].price == ""
                        ? this.pPrice
                        : this.variantList[k].price,
                    quantity: this.variantList[k].quantity,
                    name: array[i].name,
                    id: array[i].branch_id + this.variantList[k].sku,
                  });
                }
              }
            }
            const seen = new Set();
            const filteredArr = arr.filter((el) => {
              const duplicate = seen.has(el.id);
              seen.add(el.id);
              return !duplicate;
            });
            this.branchVariantList = filteredArr;
          } else {
            this.productBranchList = [];
            // product branch
            var productBranchArray = [];
            var branchProduct = this.productBranchList;
            for (let k = 0; k < branchProduct.length; k++) {
              if (branchProduct[k].stock_type == 1) {
                var branchPush = {
                  branch_id: branchProduct[k].bid,
                  variant: branchProduct[k].variant,
                  price: branchProduct[k].price,
                  quantity: branchProduct[k].daily_limit,
                  name: branchProduct[k].branch_name,
                  id: branchProduct[k].b_SKU,
                };
                productBranchArray.push(branchPush);
              } else {
                var branchPush = {
                  branch_id: branchProduct[k].bid,
                  variant: branchProduct[k].variant,
                  price: branchProduct[k].price,
                  name: branchProduct[k].branch_name,
                  quantity: branchProduct[k].stock_quantity,
                  id: branchProduct[k].b_SKU,
                };
                productBranchArray.push(branchPush);
              }
            }

            this.selectBranchVariantList = productBranchArray;

            if (this.hasVariant == "0") {
              this.variantList = [];
              this.list = [];
            } else {
              this.variantList = this.tempProductVariantList;
              this.list = this.tempProductVariantGroup;
            }
            var array = this.branch;

            this.branchVariantList = [];
            let clonedArray = [...productBranchArray];
            var arr = clonedArray;
            for (let i = 0; i < array.length; i++) {
              if (this.variantList == "") {
                arr.push({
                  branch_id: array[i].branch_id,
                  price: this.pPrice,
                  quantity:
                    this.stockType == 1
                      ? this.dailyLimit
                      : this.stockType == 2
                      ? this.stockQuantity
                      : "",
                  name: array[i].name,
                  id: array[i].branch_id + this.pSKU,
                });
              } else {
                for (let k = 0; k < this.variantList.length; k++) {
                  arr.push({
                    branch_id: array[i].branch_id,
                    variant: this.variantList[k].name,
                    price:
                      this.variantList[k].price == ""
                        ? this.pPrice
                        : this.variantList[k].price,
                    quantity: this.variantList[k].quantity,
                    name: array[i].name,
                    id: array[i].branch_id + this.variantList[k].sku,
                  });
                }
              }
            }
            const seen = new Set();
            const filteredArr = arr.filter((el) => {
              const duplicate = seen.has(el.id);
              seen.add(el.id);
              return !duplicate;
            });
            this.branchVariantList = filteredArr;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    handleClick(item) {
      this.getProductModifier(item.product_id);
      this.getProductVariantGroup(item.product_id);
      this.getProductVariant(item.product_id);
      // this.getProductBranch(item.product_id);
      this.selectProduct = item.product_id;
      this.pName = item.name;
      this.selectCategory = parseInt(item.cid);
      this.pDescription = item.description;
      this.pPrice = item.price;
      this.pSKU = item.SKU;
      this.selectUnit = item.unit == "each_c" ? "custom" : item.unit;
      this.selectUnitAmount = item.per_quantity_unit;
      this.sequenceNumber = item.sequence_number;
      this.availableSave = item.available.toString();
      this.showInQR = item.show_in_qr.toString();
      this.allowTicket = item.allow_ticket.toString();
      this.ticketNumber = item.ticket_count;
      this.ticketExp = item.ticket_exp;
      this.hasVariant = item.has_variant.toString();
      this.dailyLimit = item.daily_limit;
      this.stockQuantity = item.stock_quantity;
      this.radioValue = item.graphic_type;
      this.color = item.color;
      this.image = item.image;
      process.nextTick(() => {
        this.stockType = item.stock_type;
        this.imageName =
          item.image != "" || item.image != null
            ? this.domain + "gallery/" + this.companyID + "/" + item.image
            : "";
      });
      this.title = "Edit";
      this.lockVariant = true;
      this.openDialog();
    },

    save () {},

    getBranchForNotification(product_id) {
      const params = new URLSearchParams();
      params.append("readBranchOfProductForNotification", "done");
      params.append("product_id", product_id);
      axios({
        method: "post",
        url: this.domain + "/product/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.product;
            for (let i = 0; i < branchList.length; i++) {
              this.sendNotication(branchList[i].branch_id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteProduct() {
      if (this.selected != "") {
        const array = [];
        for (let i = 0; i < this.selected.length; i++) {
          array.push(this.selected[i].product.product_id);
        }
        const params = new URLSearchParams();
        params.append("deleteMultiProduct", "done");
        params.append("array_product_id", JSON.stringify(array));
        axios({
          method: "post",
          url: this.domain + "/product/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = this.$t("productPage.deleteSuccess");
              this.snackbar = true;
              this.snackbarColor = "success";
              this.selected = [];
              this.dialogDelete = false;
              this.choosePage();
              for (let j = 0; j < array.length; j++) {
                this.getBranchForNotification(array[j]);
              }
            } else {
              this.text = this.$t("productPage.failErrorMsg");
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("delete", "done");
        params.append("product_id", this.selectProduct);
        axios({
          method: "post",
          url: this.domain + "/product/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = this.$t("productPage.deleteSuccess");
              this.snackbar = true;
              this.snackbarColor = "success";
              this.dialogDelete = false;
              this.choosePage();
              this.getBranchForNotification(this.selectProduct);
            } else {
              this.text = this.$t("productPage.failErrorMsg");
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    dialogAction() {
      if (this.title == "Add") {
        this.buttonSave = true;
        if (this.hasVariant == "1" && this.list.length == 0) {
          this.text = this.$t("productPage.warningSetProductVariant");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (!this.$refs.form.validate()) {
          this.text = this.$t("productPage.warningFillRequried");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (this.selectBranchVariantList == "") {
          this.text = this.$t("productPage.warningSelectBranch");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (
          this.allowTicket == "1" &&
          (this.ticketExp == "" || this.ticketNumber == "")
        ) {
          this.text = this.$t("productPage.noBlankTicket");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (this.$refs.form.validate()) {
          const params = new URLSearchParams();
          params.append("addProduct", "done");
          params.append("pName", this.pName);
          params.append("pCategories", this.selectCategory);
          params.append("pDescription", this.pDescription);
          params.append("pPrice", this.pPrice);
          params.append("pSKU", this.pSKU);
          params.append("availableSale", this.availableSave);
          params.append("showInQR", this.showInQR);

          params.append("hasVariant", this.hasVariant);

          params.append("allowTicket", this.allowTicket);
          params.append("ticketNumber", this.ticketNumber);
          params.append("ticketExp", this.ticketExp);

          params.append("stockType", this.stockType);
          params.append(
            "unit",
            this.selectUnit == "custom" ? "each_c" : this.selectUnit
          );
          params.append("perQuantityUnit", this.selectUnitAmount);
          params.append("sequenceNumber", this.sequenceNumber);
          params.append(
            "dailyLimit",
            this.stock_type == 3 ? 0 : this.dailyLimit
          );
          params.append(
            "stockQuantity",
            this.stock_type == 3 ? 0 : this.stockQuantity
          );
          params.append("modifier", JSON.stringify(this.switch1));
          params.append("variant", JSON.stringify(this.list));
          params.append("productVariant", JSON.stringify(this.variantList));
          params.append(
            "productBranch",
            JSON.stringify(this.selectBranchVariantList)
          );
          params.append("graphic", this.radioValue);
          params.append("color", this.color);
          params.append("image", this.dataUrl);
          params.append("company_id", this.companyID);
          axios({
            method: "post",
            url: this.domain + "/product/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.imageName = "";
                this.dialog = false;
                this.text = this.$t("productPage.successCreate");
                this.snackbar = true;
                this.snackbarColor = "success";
                this.buttonSave = false;
                this.choosePage();
                var arr = this.selectBranchVariantList;
                var clean = arr.filter(
                  (arr, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.save === arr.save && t.branch_id === arr.branch_id
                    )
                );
                for (let i = 0; i < clean.length; i++) {
                  this.sendNotication(clean[i].branch_id);
                }
                this.myCroppa.refresh();
              } else if (response.data.status == "2") {
                this.text = this.$t("productPage.noRepeatSKU");
                this.snackbar = true;
                this.snackbarColor = "error";
                this.buttonSave = false;
              } else {
                this.text = this.$t("productPage.failErrorMsg");
                this.snackbar = true;
                this.snackbarColor = "error";
                this.buttonSave = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        this.buttonSave = true;
        if (this.hasVariant == "1" && this.list.length == 0) {
          this.text = this.$t("productPage.warningSetProductVariant");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (!this.$refs.form.validate()) {
          this.text = this.$t("productPage.warningFillRequried");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (this.selectBranchVariantList == "") {
          this.text = this.$t("productPage.warningSelectBranch");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (
          this.allowTicket == "1" &&
          (this.ticketExp == "" || this.ticketNumber == "")
        ) {
          this.text = this.$t("productPage.noBlankTicket");
          this.snackbar = true;
          this.snackbarColor = "warning";
          this.buttonSave = false;
        } else if (this.$refs.form.validate()) {
          const params = new URLSearchParams();
          params.append("editProduct", "done");
          params.append("pID", this.selectProduct);
          params.append("pOldImage", this.image);
          params.append("pName", this.pName);
          params.append("pCategories", this.selectCategory);
          params.append("pDescription", this.pDescription);
          params.append("pPrice", this.pPrice);
          params.append("pSKU", this.pSKU);
          params.append("availableSale", this.availableSave);
          params.append("showInQR", this.showInQR);
          params.append("hasVariant", this.hasVariant);

          params.append("allowTicket", this.allowTicket);
          params.append("ticketNumber", this.ticketNumber);
          params.append("ticketExp", this.ticketExp);

          params.append("stockType", this.stockType);
          params.append(
            "unit",
            this.selectUnit == "custom" ? "each_c" : this.selectUnit
          );
          params.append("perQuantityUnit", this.selectUnitAmount);
          params.append("sequenceNumber", this.sequenceNumber);
          params.append(
            "dailyLimit",
            this.stock_type == 3 ? 0 : this.dailyLimit
          );
          params.append(
            "stockQuantity",
            this.stock_type == 3 ? 0 : this.stockQuantity
          );
          params.append("modifier", JSON.stringify(this.switch1));
          params.append("variant", JSON.stringify(this.list));
          params.append("productVariant", JSON.stringify(this.variantList));
          params.append(
            "productBranch",
            JSON.stringify(this.selectBranchVariantList)
          );
          params.append("graphic", this.radioValue);
          params.append("color", this.color);
          params.append("image", this.dataUrl);
          params.append("company_id", this.companyID);
          axios({
            method: "post",
            url: this.domain + "/product/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.imageName = "";
                this.dialog = false;
                this.text = this.$t("productPage.successEdit");
                this.snackbar = true;
                this.snackbarColor = "success";
                this.buttonSave = false;
                this.choosePage();
                if (this.radioValue == 2) {
                  this.myCroppa.refresh();
                }
                var arr = this.selectBranchVariantList;
                var clean = arr.filter(
                  (arr, index, self) =>
                    index ===
                    self.findIndex(
                      (t) =>
                        t.save === arr.save && t.branch_id === arr.branch_id
                    )
                );
                for (let i = 0; i < clean.length; i++) {
                  this.sendNotication(clean[i].branch_id);
                }
              } else if (response.data.status == "2") {
                this.text = this.$t("productPage.noRepeatSKU");
                this.snackbar = true;
                this.snackbarColor = "error";
                this.buttonSave = false;
              } else {
                this.text = this.$t("productPage.failErrorMsg");
                this.snackbar = true;
                this.snackbarColor = "error";
                this.buttonSave = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.row-pointer {
  cursor: pointer;
}
.v-input--selection-controls .v-input__slot > .v-label {
  flex-direction: column;
  align-items: flex-start;
}
.croppa-container {
  font-size: 0 !important;
}
</style>
<style lang="scss">
.v-input--reverse .v-input__slot {
  flex-direction: row-reverse;
  justify-content: flex-end;
  .v-application--is-ltr & {
    .v-input--selection-controls__input {
      margin-right: 0;
      margin-left: 8px;
    }
  }
  .v-application--is-rtl & {
    .v-input--selection-controls__input {
      margin-left: 0;
      margin-right: 8px;
    }
  }
}
</style>